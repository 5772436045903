<template>
  <v-container class="center-screen">
    <v-card width="100%">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title class="secondary--text">Login</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="secondary" class="mr-4" text @click.prevent="$router.push({ name: 'reset_password' })"
          >Passwort vergessen?</v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="state.forms.login.is_valid" lazy-validation>
          <v-text-field
            v-model="viewmodel.form_data.login.verein_id"
            :rules="viewmodel.form_data.login.rules.verein_id"
            label="VereinsID oder Abteilung@VereinsID"
            required
          ></v-text-field>

          <v-text-field
            v-model="viewmodel.form_data.login.password"
            :rules="viewmodel.form_data.login.rules.password"
            :append-icon="state.forms.login.show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :type="state.forms.login.show_password ? 'text' : 'password'"
            @click:append="state.forms.login.show_password = !state.forms.login.show_password"
            label="Passwort"
            required
            v-on:keyup.enter="validate"
          ></v-text-field>

          <v-btn :disabled="!state.forms.login.is_valid" color="primary" class="mr-4" @click.prevent="validate">
            OK
          </v-btn>

          <v-btn color="error" class="mr-4" @click="reset">
            Abbrechen
          </v-btn>
        </v-form>
        <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="$store.state.is_loading">
        </v-progress-linear>
        <v-alert
          class="mt-4"
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="state.forms.login.alert_type"
          elevation="2"
          v-model="state.forms.login.alert"
          >{{ state.forms.login.response }}</v-alert
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    state: {
      forms: {
        login: {
          is_valid: true,
          show_password: false,
          alert_type: 'info',
          alert: false,
          response: '',
        },
      },
    },
    viewmodel: {
      form_data: {
        login: {
          verein_id: '',
          password: '',
          rules: {
            verein_id: [(v) => !!v || 'Pflichtfeld'],
            password: [(v) => !!v || 'Pflichtfeld'],
          },
        },
      },
    },
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.state.forms.login.is_valid) {
        this.login();
      }
    },
    reset() {
      this.$refs.form.reset();
      this.state.forms.login.alert = false;
      this.state.forms.login.alert_type = 'info';
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    login() {
      const user = {
        verein_id: this.viewmodel.form_data.login.verein_id,
        password: this.viewmodel.form_data.login.password,
      };
      this.$store
        .dispatch('login', user)
        .then(() => {
          this.state.forms.login.alert = false;
          this.state.forms.login.alert_type = 'success';
          this.state.forms.login.response = 'OK';
          this.$router.push('/admin');
        })
        .catch(() => {
          this.state.forms.login.alert = true;
          this.state.forms.login.alert_type = 'error';
          this.state.forms.login.response = 'Passwort oder VereinsID ungültig.';
        });
    },
  },
};
</script>

<style scoped>
.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
</style>
